<template>
  <Renderer ref="renderer" resize="'window'" height="100%" width="100%" shadow antialias :orbit-ctrl="{ autoRotate: true, enableDamping: true, dampingFactor: 0.05 }">
    <Camera :position="{ x: 0, y: 0, z: 10 }" />
    <Scene background="#ffffff">
      <Plane ref="mesh" color="#7C0A02" :scale="{ x: 5, y: 7, z: 5 }"  >
        <MatcapMaterial  name="392307_B3AE7D_6D5618_847C42" >
          <Texture src="https://media.discordapp.net/attachments/546436101666242560/909115781168652308/PicsArt_11-13-11.22.10.gif" />
         </MatcapMaterial>

       </Plane>
     </Scene>
   </Renderer>
 </template>

 <script>

 import { Plane, Camera, MatcapMaterial, Renderer, Scene , Texture } from 'troisjs';

 export default {
   name: "OneNftDisplay",
   components: { Plane, Camera, MatcapMaterial, Renderer, Scene , Texture },



 }
 </script>

 <style scoped>

 </style>
