<template>
  <div >
    <button  v-if="modelDisplayed != 'basic'" @click="showModel('basic')" >See basic model</button>
    <button  v-if="modelDisplayed != 'fbx1'" @click="showModel('fbx1')" >See fbx  model 1</button>
    <button  v-if="modelDisplayed != 'fbx2'" @click="showModel('fbx2')" >See fbx model 2</button>
    <OneNftDisplay v-if="modelDisplayed === 'basic'" class="nft_display"  />
    <FbxModel filePath="/SambaDancing.fbx" v-else-if="modelDisplayed === 'fbx1'" class="nft_display"/>
    <FbxModel filePath="/Strut_Walking.fbx" v-else-if="modelDisplayed === 'fbx2'" class="nft_display"/>
  </div>
</template>

<script>
import OneNftDisplay from "./components/OneNftDisplay";
import FbxModel from "./components/FbxModel";
export default {
  name: 'App',
  components: {
    OneNftDisplay,FbxModel
  },
  data: () => ({
    modelDisplayed : null
}),
  methods:{
    showModel(modelName){
      this.modelDisplayed = modelName;
    }
  }
}
</script>

<style>
.nft_display{
  height: 80%;
  width: 80%;
}
</style>
